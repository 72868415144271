<template>
  <div
    class="card flex flex-col rounded-lg bg-white cursor-pointer ease-in-out transition transition-border"
    :class="{ hover }"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    @click="$router.push(link)"
  >
    <div class="flex-none h-[160px]">
      <picture v-if="post.image.src" class="block h-full">
        <source
          v-for="(source, index) in post.image.sources"
          :key="`${source.srcset}-${index}`"
          :type="source.type"
          :srcset="source.srcset"
        />
        <nuxt-img
          class="block h-full object-fit picture rounded-t-lg"
          :src="post.image.src"
          :srcset="post.image.srcset"
          :alt="post.title"
          width="270"
          height="160"
          fit="cover"
        ></nuxt-img>
      </picture>
    </div>

    <div
      class="flex-auto bg-white rounded-b-lg border-t-0 border"
      :class="hover ? 'border-transparent' : 'border-gray-600'"
    >
      <div class="flex flex-wrap justify-between border-b border-sable mb-4 px-5 py-4">
        <span class="w-full text-xs text-black-600 py-2 whitespace-nowrap">{{ post.date }}</span>
        <span
          v-if="post.tags.length > 0"
          @click.stop="
            $router.push(
              localePath({
                name: 'information-subpage-index-category-news',
                params: { category: 'actualites', tag: post.tags[0].slug },
              }),
            )
          "
          class="rounded-lg rounded-br-none flex items-center text-xs h-7.5 px-5 bg-sable text-blue border-blue border whitespace-nowrap mr-1 hover:bg-blue hover:text-white transition duration-300"
        >
          {{ post.tags[0].title }}
        </span>
      </div>
      <div class="px-5">
        <h4 class="h-18 mb-4 font-semibold line-clamp-3" style="line-height: 23px">{{ post.title }}</h4>
        <p class="text-sm line-clamp-2 leading-5.5 lg:leading-6">{{ post.excerpt }}</p>
        <nuxt-link
          class="flex justify-end items-center text-sm py-3.5"
          :class="hover ? 'text-black' : ' text-gray-700'"
          :to="
            localePath({
              name: 'information-subpage-index-category-news-slug',
              params: { tag: post.tags[0].slug, post: post.slug },
            })
          "
        >
          {{ t("read-more") }}
          <arrow-small class="ml-2 stroke-current text-purple-600" width="7px" height="9px"></arrow-small>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowSmall from "~/assets/svg/arrow-small.svg";

export default {
  name: "CardPost",
  components: {
    ArrowSmall,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  setup() {
    const { t, locale } = useI18n({
      useScope: "local",
    });
    const localePath = useLocalePath();

    return { t, locale, localePath };
  },
  computed: {
    link() {
      return this.localePath({
        name: "information-subpage-index-category-news-slug",
        params: { tag: this.post.tags[0].slug, post: this.post.slug },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  &.no-min {
    width: 100%;
    max-width: 270px;
  }

  &:not(.no-min) {
    width: 270px;
    min-width: 270px;
  }

  position: relative;

  &::before {
    border-radius: 8px;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }

  &.hover {
    &::before {
      border: 1px solid #8f8f8f;
    }
  }
}
</style>

<i18n lang="json">
{
  "fr": {
    "read-more": "Lire plus"
  },
  "en": {
    "read-more": "Read more"
  }
}
</i18n>
